import React from "react"
// import { Link } from "gatsby"
import { connect, useSelector } from "react-redux"

import { RiskDisclaimerQuery } from "../../prismic/staticQueries"
import Layout from "../../components/layout"
import ContentBasic from "../../components/content-basic"
// import { IMAGES } from '../../constants';

const RiskDisclaimer = () => {
  const language = useSelector(state => state.language)
  const riskDisclaimerPageData = RiskDisclaimerQuery(language)
  return (
    <>
      <Layout>
        <ContentBasic {...riskDisclaimerPageData} linksTarget="_blank" />
      </Layout>
    </>
  )
}

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

export default connect(mapStateToProps)(RiskDisclaimer)
